import logo from "../media/meal-mate.png";

const PageHeader = () => {
  return (
    <div style={{ marginBottom: "2em" }}>
      <a href="/">
        <img
          style={{ width: "350px", height: "auto" }}
          src={logo}
          alt="Meal Mate"
        ></img>
      </a>
    </div>
  );
};

export default PageHeader;
