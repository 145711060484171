import { useState } from "react";
import baseUrl from "../api";
import { useNavigate } from "react-router-dom";
import "./components.css";

//======================================================
const JoinPage = (props) => {
  const { session, setSession } = props;
  const [pairCode, setPairCode] = useState();

  const navigate = useNavigate();

  const startNewSession = async () => {
    const resp = await fetch(`${baseUrl}/sessions`, { method: "POST" });
    const info = await resp.json();
    props.setUser(info.user);
    setSession(info);
    navigate("/locate");
  };

  const joinExistingSession = async () => {
    const resp = await fetch(`${baseUrl}/users/${pairCode}`, {
      method: "POST",
    });
    const info = await resp.json();
    props.setUser(info.user);
    setSession(info);
    navigate("/sorting");
  };

  return (
    <div style={{}}>
      <div className="join_page_div">
        <button onClick={startNewSession}>Create New Session</button>
      </div>
      <div style={{ height: "2em" }}></div>
      <div className="join_page_div">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            joinExistingSession();
          }}
        >
          <label>Enter Pair Code: </label>
          <input
            onChange={(e) => {
              setPairCode(e.target.value);
            }}
            placeholder="Pair Code"
          ></input>
          <br></br>
          <br></br>
          <button>Join Existing Session</button>
        </form>
      </div>
    </div>
  );
};

export default JoinPage;
