import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import g$ from "../media/greendollar.png";
import b$ from "../media/dollar.png";
import twitter from "../media/twitter.png";
import instagram from "../media/instagram.png";
import facebook from "../media/facebook.png";
import spinner from "../media/pizzaspinner.png";

const MatchFound = (props) => {
  const { matchVenue } = props;
  const navigate = useNavigate();

  const facebookLink = `http://facebook.com/${matchVenue.social_media.facebook_id}`;
  const instagramLink = `http://instagram.com/${matchVenue.social_media.instagram}`;
  const twitterLink = `http://twitter.com/${matchVenue.social_media.twitter}`;
  const telephone = `tel:${matchVenue.tel}`;

  // useEffect(() => {
  //   console.log(matchVenue);
  // }, []);

  const loadMatchVenue = () => {
    return (
      <div className="venue_sort_card">
        <div className="img_logos">
          <div className="venue_image_container">
            {matchVenue.photos[0] && (
              <img
                className="venue_image"
                src={`${matchVenue.photos[0].prefix}width250${matchVenue.photos[0].suffix}`}
              ></img>
            )}
          </div>
          <div className="logos">
            {matchVenue.social_media.facebook_id && (
              <a href={facebookLink} target="_blank">
                <img src={facebook}></img>
              </a>
            )}
            {matchVenue.social_media.twitter && (
              <a href={twitterLink} target="_blank">
                <img src={twitter}></img>
              </a>
            )}
            {matchVenue.social_media.instagram && (
              <a href={instagramLink} target="_blank">
                <img src={instagram}></img>
              </a>
            )}
          </div>
        </div>
        <div className="venue_info_container">
          <h4 className="venue_title">{matchVenue.name}</h4>
          {/* {matchVenue.categories.map((category) => {
          return <span>{category.name.replace(" Restaurant", "/")}</span>;
        })} */}
          {matchVenue.rating && (
            <p>
              {matchVenue.rating}/10 ({matchVenue.stats.total_ratings} ratings)
            </p>
          )}
          <span style={{ paddingTop: "100px" }}>
            {matchVenue.categories[0].name} -{" "}
          </span>
          {matchVenue.price === 1 && (
            <span className="price">
              <span>
                <img src={g$}></img>
              </span>
              <span>
                <img src={b$}></img>
              </span>
              <span>
                <img src={b$}></img>
              </span>
              <span>
                <img src={b$}></img>
              </span>
            </span>
          )}
          {matchVenue.price === 2 && (
            <span className="price">
              <span>
                <img src={g$}></img>
              </span>
              <span>
                <img src={g$}></img>
              </span>
              <span>
                <img src={b$}></img>
              </span>
              <span>
                <img src={b$}></img>
              </span>
            </span>
          )}
          {matchVenue.price === 3 && (
            <span className="price">
              <span>
                <img src={g$}></img>
              </span>
              <span>
                <img src={g$}></img>
              </span>
              <span>
                <img src={g$}></img>
              </span>
              <span>
                <img src={b$}></img>
              </span>
            </span>
          )}
          {matchVenue.price === 4 && (
            <span className="price">
              <span>
                <img src={g$}></img>
              </span>
              <span>
                <img src={g$}></img>
              </span>
              <span>
                <img src={g$}></img>
              </span>
              <span>
                <img src={g$}></img>
              </span>
            </span>
          )}
          <p>
            {matchVenue.location.address}, {matchVenue.location.locality}
          </p>

          <p>{matchVenue.description}</p>
          <p>{matchVenue.hours.display}</p>
          <a target="_blank" href={matchVenue.website}>
            {matchVenue.website}
          </a>
          <br></br>
          <a href={telephone}>{matchVenue.tel}</a>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="pair_code_display">
        A match has been found!&nbsp;&nbsp;
        <a href="/">Match Again</a>
      </div>
      <div>
        {matchVenue ? (
          loadMatchVenue()
        ) : (
          <img className="spinner" src={spinner}></img>
        )}
      </div>
    </div>
  );
};

export default MatchFound;
