import { useEffect, useState } from "react";
import baseUrl from "../api";
import upvote from "../media/upvote.png";
import downvote from "../media/downvote.png";
import g$ from "../media/greendollar.png";
import b$ from "../media/dollar.png";
import spinner from "../media/pizzaspinner.png";
import { useNavigate } from "react-router-dom";
import "./components.css";

//=======================================================

const Sorting = (props) => {
  const [venues, setVenues] = useState(null);
  // const [nextVenuesLink, setNextVenuesLink]
  const [count, setCount] = useState(0);
  const navigate = useNavigate();

  //=======================================================

  const venueSearch = async () => {
    try {
      const searchParams = new URLSearchParams({
        query: "food",
        near: props.location,
        exclude_all_chains: props.session.exclude_chains,
        fields:
          "name,fsq_id,categories,distance,location,description,hours,rating,price,website,social_media,photos,features,stats,tel",
        open_now: true,
        sort: "DISTANCE",
        // radius: "100000",
        limit: 50,
      });
      const results = await fetch(
        `https://api.foursquare.com/v3/places/search?${searchParams}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "fsq3mhHdIebUCyb7NiP/6WPi4LXNtv+5GOceJIHT6O8j4pE=",
          },
        }
      );
      const data = await results.json();
      console.log(results.url);
      setVenues(data.results);
      return data;
    } catch (err) {
      console.error(err);
    }
  };

  //=======================================================

  useEffect(() => {
    venueSearch();
  }, [props.session]);

  useEffect(() => {
    console.log(venues);
  }, [venues]);

  useEffect(() => {
    getSession();
  }, []);

  //=======================================================

  const getSession = async () => {
    const sessionId = props.session.id;
    const response = await fetch(`${baseUrl}/sessions/${sessionId}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    const newSession = await response.json();
    props.setSession(newSession);
    props.setLocation(newSession.zip_code);
  };

  //=======================================================

  const likeVenue = async () => {
    await fetch(`${baseUrl}/users_sessions/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: props.user.id,
        sessionId: props.session.id,
        likedVenueId: venues[count].fsq_id,
      }),
    });
  };

  //=======================================================

  const checkMatch = async () => {
    const matchResp = await fetch(
      `${baseUrl}/sessions/match/${props.session.id}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const result = await matchResp.json();
    if (result.match_found === "yes") {
      console.log(result.match_found);
      console.log(result.match_venue_object);
      props.setMatchVenue(result.match_venue_object);
      navigate("/success");
    }

    const resp = await fetch(`${baseUrl}/users_sessions/match`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        sessionId: props.session.id,
        likedVenueId: venues[count].fsq_id,
      }),
    });
    const check = await resp.json();
    if (props.session.user_count > 1) {
      if (check.count === props.session.user_count.toString()) {
        const resp = await fetch(
          `${baseUrl}/sessions/match/${props.session.id}`,
          {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(venues[count]),
          }
        );

        props.setMatchVenue(venues[count]);
        navigate("/success");
      } else {
        // console.log("no match");
        return false;
      }
    } else {
      // console.log("no match");
      return false;
    }
  };

  //=======================================================

  const loadVenue = () => {
    return (
      <div className="venue_sort_card">
        <div className="venue_image_container">
          {venues[count].photos[0] && (
            <img
              className="venue_image"
              src={`${venues[count].photos[0].prefix}width250${venues[count].photos[0].suffix}`}
            ></img>
          )}
        </div>
        <div className="venue_info_container">
          <h4 className="venue_title">{venues[count].name}</h4>
          {/* {venues[count].categories.map((category) => {
          return <span>{category.name.replace(" Restaurant", "/")}</span>;
        })} */}
          {venues[count].rating && (
            <p>
              {venues[count].rating}/10 ({venues[count].stats.total_ratings}{" "}
              ratings)
            </p>
          )}
          <span style={{ paddingTop: "100px" }}>
            {venues[count].categories[0].name} -{" "}
          </span>
          {venues[count].price === 1 && (
            <span className="price">
              <span>
                <img src={g$}></img>
              </span>
              <span>
                <img src={b$}></img>
              </span>
              <span>
                <img src={b$}></img>
              </span>
              <span>
                <img src={b$}></img>
              </span>
            </span>
          )}
          {venues[count].price === 2 && (
            <span className="price">
              <span>
                <img src={g$}></img>
              </span>
              <span>
                <img src={g$}></img>
              </span>
              <span>
                <img src={b$}></img>
              </span>
              <span>
                <img src={b$}></img>
              </span>
            </span>
          )}
          {venues[count].price === 3 && (
            <span className="price">
              <span>
                <img src={g$}></img>
              </span>
              <span>
                <img src={g$}></img>
              </span>
              <span>
                <img src={g$}></img>
              </span>
              <span>
                <img src={b$}></img>
              </span>
            </span>
          )}
          {venues[count].price === 4 && (
            <span className="price">
              <span>
                <img src={g$}></img>
              </span>
              <span>
                <img src={g$}></img>
              </span>
              <span>
                <img src={g$}></img>
              </span>
              <span>
                <img src={g$}></img>
              </span>
            </span>
          )}
          <p>
            {venues[count].location.address}, {venues[count].location.locality}
          </p>

          {/* <p>{venues[count].description}</p> */}
        </div>

        <div className="votebuttons">
          <img
            onClick={() => {
              likeVenue();
              checkMatch();
              setCount(count + 1);
              loadVenue();
            }}
            src={upvote}
            alt="Like"
          ></img>
          <img
            onClick={() => {
              setCount(count + 1);
              loadVenue();
            }}
            src={downvote}
            alt="Dislike"
          ></img>
        </div>
      </div>
    );
  };

  //=======================================================

  return (
    <div>
      <div className="pair_code_display">
        Session pair code: {props.session.pair_code}
      </div>
      <div>
        {venues ? loadVenue() : <img className="spinner" src={spinner}></img>}
      </div>
    </div>
  );
};

export default Sorting;
