import { useState } from "react";
import EnterLocation from "./components/EnterLocation";
import JoinPage from "./components/JoinPage";
import PageHeader from "./components/PageHeader";
import Sorting from "./components/Sorting";
import React, { Fragment } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import MatchFound from "./components/MatchFound";

function App() {
  const [session, setSession] = useState();
  const [location, setLocation] = useState();
  const [user, setUser] = useState();
  const [matchVenue, setMatchVenue] = useState();

  return (
    <div style={{ textAlign: "center", margin: "1em" }}>
      <Router>
        <Fragment>
          <PageHeader />

          <Routes>
            <Route exact path="/" element={<PrivateRoute />}>
              <Route
                exact
                path="/"
                element={
                  <JoinPage
                    session={session}
                    setSession={setSession}
                    setUser={setUser}
                  />
                }
              />
              <Route
                exact
                path="/locate"
                element={<EnterLocation session={session} />}
              />
              <Route
                exact
                path="/sorting"
                element={
                  <Sorting
                    setLocation={setLocation}
                    session={session}
                    setSession={setSession}
                    location={location}
                    user={user}
                    setMatchVenue={setMatchVenue}
                  />
                }
              />
              <Route
                exact
                path="/success"
                element={<MatchFound matchVenue={matchVenue} />}
              />
            </Route>
          </Routes>
        </Fragment>
      </Router>
    </div>
  );
}

export default App;
