import { useEffect, useState } from "react";
import baseUrl from "../api";
import { useNavigate } from "react-router-dom";
import "./components.css";

const EnterLocation = (props) => {
  const [sessionLocation, setSessionLocation] = useState();
  const [excludeChains, setExcludeChains] = useState("false");
  const navigate = useNavigate();

  const sendLocation = async (e) => {
    e.preventDefault();
    await fetch(`${baseUrl}/sessions/${props.session.id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        zipCode: sessionLocation,
        excludeChains: excludeChains,
      }),
    });
    navigate("/sorting");
  };

  // useEffect(() => {
  //   if (window.navigator.geolocation) {
  //     window.navigator.geolocation.getCurrentPosition(console.log, console.log);
  //   }
  // }, []);

  return (
    <div>
      <div
        style={{ fontSize: "1.5em", marginBottom: "1em", fontWeight: "bold" }}
      >
        Session pair code: {props.session.pair_code}
      </div>
      <div className="join_page_div">
        <h3>Enter location:</h3>
        <form
          onSubmit={(e) => {
            sendLocation(e);
          }}
        >
          <input
            placeholder="Zip Code"
            onChange={(e) => {
              setSessionLocation(e.target.value);
            }}
          ></input>
          <br></br>
          <br></br>
          <input
            type="checkbox"
            id="exclude_chains"
            name="exclude_chains"
            onChange={() => {
              if (excludeChains === "true") {
                setExcludeChains("false");
              } else {
                setExcludeChains("true");
              }
            }}
          />
          <label>Exclude chain restaurants</label>
          <br></br>
          <br></br>
          <button>Enter</button>
        </form>
      </div>
    </div>
  );
};

export default EnterLocation;
